.App {
  text-align: center;
}
.videoBox {
  width: 100%;
  background-color: black;
  height: calc(85vh - 100px);
}
.buttonsBox {
  transition: opacity 1s;
  display: flex;
  justify-content: space-evenly;
}
.buttons {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 24px;
  margin: 40px 0px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.buttons:hover {
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  background-color: #e9e9e9;
}
.buttons:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms linear 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms linear;
}

.fade-exit-done {
  opacity: 0;
}
